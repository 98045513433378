import styles from "./Partners.module.scss";
import Image from "next/image";
import { FC, useRef } from "react";
import Carousel from 'react-elastic-carousel';
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { CAROUSEL_BREAKPOINT, PARTNERS } from "./consts";

export const Arrow = ({ type, onClick, isEdge }:any) => (
  <button onClick={onClick} disabled={isEdge}>
    {
      type === "PREV"
        ? <FaAngleLeft className={styles.arrow}/>
        : <FaAngleRight className={styles.arrow}/>
    }
  </button>
)

const PartnerLogos: FC<{ inverse?: boolean }> = () => {
  const carouselRef = useRef<any>(null);
  
  return (
    <div className={styles.images}>
      <Carousel
        ref={carouselRef}
        isRTL={false}
        breakPoints={CAROUSEL_BREAKPOINT}
        renderPagination={() => <> </> }
        renderArrow={Arrow }
      >
        {
          PARTNERS.map((partner, key) => (
            <div key={key}
              className={styles.image}
            >
              <Image {...partner} alt={partner.alt} />
            </div>
          ))
        }
      </Carousel>
    </div>
  );
}



export default PartnerLogos;
