import { useTranslation } from "next-i18next"
import Image from "next/dist/client/image"
import ReactElasticCarousel from "react-elastic-carousel"
import { Arrow } from "../../partners/PartnerLogos"
import { CAROUSEL_BREAKPOINT } from "@cs/screens/partners/consts"
import styles from "./TrustedBy.module.scss"
import JaneImg from "@cs/public/img/olympiad/jane-goodall.png"

interface QuoteProps {
  src: StaticImageData
  quote: string
  author: string
}

const QUOTES = [
  {
    name: "jane",
    image: JaneImg,
  },
]

const Quote = ({ src, quote, author }: QuoteProps) => (
  <article className={styles.quote}>
    <figure className={styles.figure}>
      <Image src={src} alt={author} layout="fill" objectFit="cover" />
    </figure>
    <article className={styles.text}>
      <p>{quote}</p>
      <p>
        <strong>{author}</strong>
      </p>
    </article>
  </article>
)

const TrustedBy = () => {
  const { t } = useTranslation(["landing-page", "olympiad"])
  return (
    <section className={styles.section}>
      <h3 className={styles.h3}>{t("trusted-by")}</h3>
      <ReactElasticCarousel
        isRTL={false}
        breakPoints={CAROUSEL_BREAKPOINT}
        enableAutoPlay
        autoPlaySpeed={2000}
        renderPagination={() => <> </>}
        renderArrow={Arrow}
        showArrows={false}
      >
        {QUOTES.map((quote, key) => (
          <Quote
            key={key}
            src={quote.image}
            quote={t(`jane-quote-1`, { ns: "olympiad" })}
            author={t(`jane-quote-2`, { ns: "olympiad" })}
          />
        ))}
      </ReactElasticCarousel>
    </section>
  )
}

export default TrustedBy
