import { useTranslation } from "next-i18next";
import PartnerLogos from "./PartnerLogos";
import styles from "./Partners.module.scss"

const Partners = ({color}:{color?: "blue" | "grey"}) => {
  const {t} = useTranslation("common")
  return (
    <section
      className={styles[
        `sectionPartners${
          color
            ? '-' + color
            : ''
        }`
      ]}
    >
      <h2 className={styles.partners}>
        {t("partners-heading")}
      </h2>
      <PartnerLogos />
    </section>
  );
}

export default Partners;