import Image from "next/image"
import { Trans, useTranslation } from "next-i18next"
import CurvedBackground from "@cs/public/img/landing-page/curved-hero-background.png"
import Trees from "@cs/public/img/landing-page/trees.png"
import styles from "./SectionHero.module.scss"
import { FaAngleRight } from "react-icons/fa"
import CourseButton from "@cs/components/courses/course-button/CourseButton"
import Link from "next/link"
import YoutubeBox from "../components/youtube-box/YoutubeBox"

interface SectionHeroProps {
  /*
   * Represents the Map of strings that will be supplied by container
   */
  strings: { [key: string]: string }
}

const SectionHero: React.FC<SectionHeroProps> = props => {
  const { t } = useTranslation("landing-page")

  return (
    <div className={styles.container}>
      <div className={styles.curvedBackgroundContainer}>
        <Image
          src={CurvedBackground}
          alt="beautiful-curved-background"
          layout={"responsive"}
          width={300}
          height={58}
        />
      </div>
      <div className={styles.treesContainer}>
        <Image src={Trees} alt="beautiful-curved-background" layout={"responsive"} />
      </div>
      <div className={styles.mainContent}>
        <div className={styles.textContent}>
          <h1 className={styles.heading}>
            <Trans t={t} i18nKey={props.strings.heading} />
          </h1>

          <p className={styles.subHeading}>{t(props.strings.subHeading)}</p>

          <Link href={`/new-courses/crash-course`} passHref>
            <CourseButton className={styles.actionButton}>
              {t(props.strings.actionButton)}
              <FaAngleRight />
            </CourseButton>
          </Link>
        </div>

        <div className={styles.youtube}>
          <YoutubeBox title={t("what-is-CS")} youtubeId="7fhG8rjnTsg" />
        </div>
      </div>
    </div>
  )
}

export default SectionHero
