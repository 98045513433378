import { GetStaticProps } from "next/types";
import nextI18nextConfig from "next-i18next.config";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { Page } from "types/page";
import LandingPage from "@cs/screens/landing-page";
import {
  LeadershipMember,
  TeamMember,
} from "@cs/lib/firebase/team/loadTeam";
import { SSRConfig } from "next-i18next";

const Home: Page<LandingPageProps> = () => <LandingPage/>;

export default Home;

interface LandingPageProps extends SSRConfig {
  leadership: LeadershipMember[],
  team: TeamMember[],
} 

export const getStaticProps: 
  GetStaticProps = async ({locale}) => ({
    props: {
      ...(await serverSideTranslations(
        locale ?? 'en',
        ["common", "footer", "nav", "seo", "landing-page", "olympiad"],
        nextI18nextConfig
      )),
    }
  })
