import { useTranslation } from "next-i18next";
import SectionHero from "./SectionHero";
import { NextSeo } from "next-seo";

const SectionHeroContainer = () => {
  const {t} = useTranslation("seo");
  return (
    <>
      <NextSeo
        title={t("landing-page.title")}
        description={t("landing-page.description")}
      />
      <SectionHero
        strings={{
          heading: "heading",
          subHeading: "sub-heading",
          actionButton: "start-learning"
        }}
      />
    </>
  );
}

export default SectionHeroContainer;
