import Courses from "@cs/public/img/landing-page/primary-products/courses-illustration.png";
import Olympiad from "@cs/public/img/landing-page/primary-products/olympiad-illustration.png";
import Schools from "@cs/public/img/landing-page/primary-products/schools-illustration.png";

export const CARDS = [
  {
    src: Courses,
    name: "courses",
    href: "/new-courses"
  },
  {
    src: Olympiad,
    name: "olympiad",
    href: "/olympiad"
  },
  {
    src: Schools,
    name: "schools",
    href: "/schools"
  }
]

export const STEPS = [
  "reaching",
  "learners",
  "languages"
]