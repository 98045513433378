import Partners from "../partners/Partners"
import SectionHero from "./section-hero"
import ProductsAndTimeline from "./section-products-and-timeline/ProductsAndTimeline"
import TrustedBy from "./section-trusted-by/TrustedBy"

const LandingPage = () => (
  <>
    <SectionHero />
    <main>
      <Partners color="blue" />
      <ProductsAndTimeline />
      <TrustedBy />
    </main>
  </>
)

export default LandingPage
