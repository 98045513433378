import { Trans, useTranslation } from "next-i18next"
import Image from "next/dist/client/image"
import { CARDS, STEPS } from "./const"
import styles from "./ProductsAndTimeline.module.scss"
import CurvedBackground from "@cs/public/img/landing-page/curved-background.png"
import Link from "next/dist/client/link"
import CourseButton from "@cs/components/courses/course-button/CourseButton"
import { FaAngleRight } from "react-icons/fa"

interface ProductProps {
  src: StaticImageData
  heading: string
  subheading: string
  href: string
}

interface StepProps {
  title: string
  text: string
}

const Product = ({ src, heading, subheading, href }: ProductProps) => (
  <Link href={href}>
    <a className={styles.card}>
      <figure className={styles.figure}>
        <Image src={src} alt={heading} layout="fill" objectFit="cover" />
      </figure>
      <div className={styles.text}>
        <h4 className={styles.h4}>{heading}</h4>
        <p className={styles.p}>{subheading}</p>
      </div>
    </a>
  </Link>
)

const Step = ({ title, text }: StepProps) => (
  <div className={styles.step}>
    <h4 className={styles.step_title}>{title}</h4>
    <p className={styles.step_text}>{text}</p>
  </div>
)

const ProductsAndTimeline = () => {
  const { t } = useTranslation("landing-page")

  return (
    <>
      <section className={styles.section}>
        <div className={styles.textContent}>
          <h2 className={styles.heading}>
            {t("learn-get-inspired")}
          </h2>
          <p className={styles.subHeading}>
            {t("enroll-in-courses")}
          </p>
        </div>

        <div className={styles.cards}>
          {CARDS.map((card, i) => (
            <Product
              key={i}
              {...card}
              heading={t(`primary-products.${card.name}.heading`)}
              subheading={t(`primary-products.${card.name}.sub-heading`)}
            />
          ))}
        </div>
        <div className={styles.timeline}>
          <h3 className={styles.h3}>
            <Trans t={t} i18nKey="bringing-climate-education" components={[<strong key={0} />]} />
          </h3>
          <div className={styles.steps}>
            {STEPS.map((step, key) => (
              <Step key={key} title={t(step)} text={t(step + "-text")} />
            ))}
          </div>
          <Link href="/join">
            <CourseButton className={styles.button}>
              {t("join-us")}
              <FaAngleRight />
            </CourseButton>
          </Link>
          <figure className={styles.figure}>
            <Image src={CurvedBackground} alt="Background figure" layout="responsive" />
          </figure>
        </div>
      </section>
    </>
  )
}

export default ProductsAndTimeline
