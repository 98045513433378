import CamzeroImage from "@cs/public/img/_common/partners/camzero.png";
import UnfcccImage from "@cs/public/img/_common/partners/unfccc.png";
import NbsiImage from "@cs/public/img/_common/partners/nbsi.png";
import GranthamInstituteImage from "@cs/public/img/_common/partners/grantham.png";
import PHDepedImage from "@cs/public/img/_common/partners/PHDeped.png";
import GSAImage from "@cs/public/img/_common/partners/GSA.png";
import StudentEnergyImage from "@cs/public/img/_common/partners/studentenergy.png";
import SchmidtImage from "@cs/public/img/_common/partners/schmidt.png";
import DixonFoundationImage from "@cs/public/img/_common/partners/DixonFoundation.png";
import ProWinImage from "@cs/public/img/_common/partners/prowin.png";
import EarthDayImage from "@cs/public/img/_common/partners/EarthDay.png";
import EarthWarriors from "@cs/public/img/_common/partners/EarthWarriors.png";
import OnePercentFTPImage from "@cs/public/img/_common/partners/onepercentfortheplanet.png";

export const CAROUSEL_BREAKPOINT = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1000, itemsToShow: 4 }
];

export const PARTNERS = [
  {
    src: CamzeroImage,
    alt: "CambridgeZero | University of Cambridge"
  },
  {
    src: UnfcccImage,
    alt: "United Nations Climate Change"
  },
  {
    src: NbsiImage,
    alt: "NBSI | University of Oxford"
  },
  {
    src: GranthamInstituteImage,
    alt: "Grantham Institute"
  },
  {
    src: PHDepedImage,
    alt: "Department of Education, Philippines"
  },
  {
    src: GSAImage,
    alt: "Green Schools Alliance"
  },
  {
    src: StudentEnergyImage,
    alt: "Student Energy"
  },
  {
    src: SchmidtImage,
    alt: "Schmidt Futures"
  },
  {
    src: DixonFoundationImage,
    alt: "Dixon Foundation"
  },
  {
    src: ProWinImage,
    alt: "Pro Win Nature Foundation"
  },
  {
    src: OnePercentFTPImage,
    alt: "1% For The Planet"
  },
  {
    src: EarthDayImage,
    alt: "Earth Day Network"
  },
  {
    src: EarthWarriors,
    alt: "Earth Day Network"
  }
]