import { FC, ReactNode } from "react"
import styles from "./CourseButton.module.scss"

interface CourseButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  light?: boolean
  underline?: boolean
  progress?: number
  disabled?: boolean
}

const CourseButton: FC<CourseButtonProps> = props => (
  <button
    {...props}
    disabled={props.disabled}
    className={`
      ${styles.button}
      ${props.disabled && styles.disabled}
      ${props.light && styles.light}
      ${props.underline && styles.underline}
      ${props.progress && props.progress < 100 && styles[`progress-${Math.floor(props.progress)}`]}
      ${props.progress && props.progress >= 100 && styles.completed}
      ${props.className}
    `}
  >
    {props.children}
  </button>
)

export default CourseButton
