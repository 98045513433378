import React, { forwardRef } from "react"
import styles from "./YoutubeBox.module.scss"

type YoutubeLoading = "lazy" | "eager"

export interface YoutubeBoxProps extends React.HTMLProps<HTMLAnchorElement> {
  youtubeId: string
  title?: string
  loading?: YoutubeLoading
}

const YoutubeBox = forwardRef<HTMLIFrameElement, YoutubeBoxProps>(
  ({ youtubeId, title = "", loading = "lazy" }, ref) => {
    const youtubeSrc = `https://www.youtube.com/embed/${youtubeId}?autoplay=1`

    const overlayImageSrc = `https://i3.ytimg.com/vi/${youtubeId}/maxresdefault.jpg`

    return (
      <div className={styles.container}>
        <div className={styles.youtubeWrapper}>
          <iframe
            ref={ref}
            src={youtubeSrc}
            title={title}
            loading={loading}
            width="960"
            height="540"
            frameBorder="0"
            allow={`accelerometer; autoplay;\
encrypted-media; gyroscope; picture-in-picture`}
            allowFullScreen
            className={styles.video}
            {...(overlayImageSrc && {
              srcDoc: `<style>*{padding:0;margin:0;overflow:hidden}\
html,body{height:100%}img,span{position:absolute;width:100%;top:0;\
bottom:0;margin:auto}span{height:1.5em;text-align:center; \
font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}\
</style><a href='${youtubeSrc}'><img src='${overlayImageSrc}' alt='${title}'>\
<span>▶</span></a>`,
            })}
          />
        </div>
      </div>
    )
  },
)

YoutubeBox.displayName = "YouTubeBox"

export default YoutubeBox
